<template>
  <!-- :ref="`JotFormIFrame-${formId}`" -->
  <!--
    The iframe was sending messages out of the intended context,
    causing data to be an object w/Nuxt properties under certain circumstances,
    so client-only is necessary
  -->
  <client-only>
    <iframe
      :ref="(el) => refAssignment(el)"
      :data-jotform-id="`JotFormIFrame-${formId}`"
      title="Solo Stove Jotform Form"
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allowfullscreen="true"
      allow="geolocation; microphone; camera"
      :src="iframeUrl"
      frameborder="0"
      style="min-width: 100%; height: 539px; border: none"
      scrolling="no"
    />
  </client-only>
</template>
<script setup lang="ts">
const props = defineProps({
  formId: {
    type: String,
    required: true,
  },
  subdomain: {
    type: String,
    default: '',
  },
});

const state = reactive({
  instanceRef: null as HTMLElement | null,
});

const baseJotformUrl = 'https://form.jotform.com';
const stoveJotformUrl = `https://${props.subdomain}.jotform.com`;
const potentialJotformUrls = [baseJotformUrl, stoveJotformUrl];

const baseDomain = computed<string>(() => {
  return props.subdomain.length > 0 ? stoveJotformUrl : baseJotformUrl;
});
const iframeUrl = computed<string>(() => `${baseDomain.value}/${props.formId}?isIframeEmbed=1`);

onBeforeMount(() => {
  init();
});

onUnmounted(() => {
  window.removeEventListener('message', handleIFrameMessage, false);
});

function init() {
  window.addEventListener('message', handleIFrameMessage, false);
}
function refAssignment(el: any) {
  state.instanceRef = el;
}

function handleIFrameMessage(e: MessageEvent) {
  const iframe = state.instanceRef as HTMLIFrameElement;

  const args = typeof e.data === 'string' ? e.data.split(':') : [''];
  const potentialJotformId = args[2];

  if (!iframe || potentialJotformId !== props.formId || !potentialJotformUrls.includes(baseDomain.value)) return;

  switch (args[0]) {
    case 'scrollIntoView':
      iframe.scrollIntoView();
      break;
    case 'setHeight':
      try {
        iframe.style.height = +args[1] + 30 + 'px';
      } catch {
        iframe.style.height = args[1] + 'px';
      }
      break;
    case 'collapseErrorPage':
      if (iframe.clientHeight > window.innerHeight) {
        iframe.style.height = window.innerHeight + 'px';
      }
      break;
    case 'reloadPage':
      window.location.reload();
      break;
    case 'loadScript': {
      if (!isPermitted(e.origin, ['jotform.com', 'jotform.pro'])) {
        break;
      }
      let src = args[1];
      if (args.length > 3) {
        src = args[1] + ':' + args[2];
      }
      const script = document.createElement('script');
      script.src = src;
      script.type = 'text/javascript';
      document.body.appendChild(script);
      break;
    }
    case 'exitFullscreen':
      if (window.document.exitFullscreen) window.document.exitFullscreen();
      else if ((window.document as any).mozCancelFullScreen) (window.document as any).mozCancelFullScreen();
      else if ((window.document as any).mozCancelFullscreen) (window.document as any).mozCancelFullScreen();
      else if ((window.document as any).webkitExitFullscreen) (window.document as any).webkitExitFullscreen();
      else if ((window.document as any).msExitFullscreen) (window.document as any).msExitFullscreen();
      break;
  }
  const isJotForm = e.origin.includes('jotform');
  if (iframe.contentWindow && isJotForm && 'contentWindow' in iframe && 'postMessage' in iframe.contentWindow) {
    const urls = {
      docurl: encodeURIComponent(document.URL),
      referrer: encodeURIComponent(document.referrer),
    };
    iframe.contentWindow.postMessage(JSON.stringify({ type: 'urls', value: urls }), '*');
  }
}
function isPermitted(originUrl: string, whitelistedDomains: string[]) {
  const url = document.createElement('a');
  url.href = originUrl;
  const hostname = url.hostname;
  let result = false;
  if (typeof hostname !== 'undefined') {
    whitelistedDomains.forEach(function (element) {
      if (hostname.slice(-1 * element.length - 1) === '.'.concat(element) || hostname === element) {
        result = true;
      }
    });
    return result;
  }
  return result;
}
</script>
